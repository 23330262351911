import React, { useRef, Children } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './index.scss';

interface FadeTransitionProps {
  itemKey: string;
  children: React.ReactNode;
}

export const FadeTransition = (props: FadeTransitionProps) => {
  const { itemKey, children } = props;
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {/* <SwitchTransition mode="out-in">
        <CSSTransition
          key={itemKey}
          nodeRef={nodeRef}
          addEndListener={(done: () => void) => {
            nodeRef.current?.addEventListener('transitionend', done, false);
          }}
          classNames="fade"
        > */}
          <div ref={nodeRef}>
            {Children.map(children, (child, index) => {
              return <>{child}</>;
            })}
          </div>
        {/* </CSSTransition>
      </SwitchTransition> */}
    </>
  );
};

export default FadeTransition;
