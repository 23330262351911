import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ExternalLinkIcon } from "@gitlab-rtsensing/component-library";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./index.scss";
import { MatomoContext } from "utils/matomo-context-provider";

interface SectionLinkProps {
  className?: string;
  children: React.ReactNode;
  url: string;
  disabled?: boolean;
  type: "internal" | "external" | "internalSection";
}

const SectionLink = (props: SectionLinkProps) => {
  const { className, disabled = false, children, url, type } = props;
  const navigate = useNavigate();
  const context = useContext(MatomoContext);
  const joinedClass = `nav-sub-section-link nav-sub-section-link${
    disabled ? "--disabled" : "--enabled"
  } ${className ?? ""}`;
  return (
    <>
      {type === "external" && (
        <a className={joinedClass} href={url} target="_blank" rel="noreferrer">
          {children}
          <ExternalLinkIcon
            className="external-link-icon"
            width={14}
            height={14}
          />
        </a>
      )}
      {type === "internal" && (
        <Link
          className={joinedClass}
          to={url}
          onClick={(e) => {
            context.trackApplicationFeature(
              "btn_click_event",
              "Side Navigation",
              url
            );
            context.trackPageView(window.location.origin + url, document.title);
          }}
        >
          {children}
        </Link>
      )}

      {type === "internalSection" && (
        // <AnchorLink
        //   offset={() => 70}
        //   onClick={(e) => {
        //     navigate(url);
        //   }}
        //   className={joinedClass}
        //   href={url}
        //   rel="noreferrer"
        // >
        <>
          {children}
         {/* </AnchorLink> */}
        </>
      )}
    </>
  );
};

export default SectionLink;
